import { Component, OnInit } from '@angular/core';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { ModalService } from '@shared/services/modal.service';
import { Auth } from 'aws-amplify';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-image-capture-history',
  templateUrl: './image-capture-history.component.html',
  styleUrls: ['./image-capture-history.component.scss']
})
export class ImageCaptureHistoryComponent implements OnInit {
  isLoading: boolean = true;

  p: number = 1;
  
  historyArray= [];
  limit = 10;
  offset= 0;
  totalCount: number;
  sortBy: string = '';
  ascDesc: string = '';

  monthOptions = [
    {
      month: 'All',
      value: ''
    },
    {
      month: 'January',
      value: '01'
    },
    {
      month: 'February',
      value: '02'
    },
    {
      month: 'March',
      value: '03'
    },
    {
      month: 'April',
      value: '04'
    },
    {
      month: 'May',
      value: '05'
    },
    {
      month: 'June',
      value: '06'
    },
    {
      month: 'July',
      value: '07'
    },
    {
      month: 'August',
      value: '08'
    },
    {
      month: 'September',
      value: '09'
    },
    {
      month: 'October',
      value: '10'
    },
    {
      month: 'November',
      value: '11'
    },
    {
      month: 'December',
      value: '12'
    }
  ]
  convertMonth:number;
  selectedMonth:string;
  yearOptions = [];
  selectedYear:string;

  //sort
  page=1;
  prevPage=1;
  defPage=1;
  public toggleSort: boolean = true;
  public isSortedAgain: string;
  public selectedSort: string;
  userAccountInfo = [];
  userEmail:string;
  userName:string;
  meterNumber: string;

  constructor(
    private hhtApiService: HhtApiService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.meterNumber = params.meter_number;
      }
    );
    var d = new Date();
    var currentMonth = d.getMonth();
    this.convertMonth = (currentMonth + 1);
    var month_ = "";
    if (this.convertMonth.toString().length == 1){
      month_ = "0" + this.convertMonth.toString();
    }
    else {
      month_ = this.convertMonth.toString()
    }
    // this.selectedMonth =  month_;
    this.selectedMonth =  '';

    var currentYear = d.getFullYear();
    var startYear = currentYear - 5;
    for(let i=0; i<6; i++){
      let yearOption = startYear + i;
      this.yearOptions.push(yearOption)
    }

    this.selectedYear = currentYear.toString();

    this.authService.isVerified().subscribe(data => {
      this.userName = String(data['username']).replace(environment.adPrefix, '');
      console.log("befor auth UserName :" + this.userName)
      this.hhtApiService.getAllImageCaptureHistory(this.meterNumber, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.sortBy, this.ascDesc, this.userName).subscribe((res: any[])=>{
        
        this.historyArray= res['body']['results'];
        this.totalCount= res['body']['details'][0]['total_count'];
  
        this.isLoading = false;
      })
    });

    Auth.currentSession().then( data => {
      this.userEmail = data.getIdToken().payload.email;
      this.userName = String(data.getIdToken().payload['cognito:username']).replace(environment.adPrefix, '');
      //this.myService.changeMessage(this.userName)
      console.log("Email :" + this.userEmail)
      console.log("UserName :" + this.userName)
      this.authService.assignCurrentUser(this.userName);
      
      this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
        console.log(res['body']['UserInfo'])
        this.userAccountInfo = res['body']['UserInfo']
        let currentUser = this.authService.getCurrentUser();
        
        if(this.userAccountInfo.length > 0){       
          console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
          const d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          console.log(year + '-' + month + '-' + day)

          if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

          }
          else {
            if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
              this.router.navigate(['hht/change-password', {mustChangePass: true}], 
              { 
              });
            }
          }
          
        }
        else {
          console.log('Credential does not exist in db')
          if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
            this.router.navigate(['hht/change-password', {mustChangePass: true}], 
            { 
            });
          }
          
        }
      })
    });
  }
  

  onSort(param: string) {
    let currentIdx = (this.defPage - 1) * this.limit
    let currentList = this.historyArray.slice(currentIdx, currentIdx + this.limit)
    
    if (this.prevPage !== this.page){
      this.isSortedAgain = ''
      this.prevPage = this.page
      this.toggleSort = true;
    }
    
    if (param === this.isSortedAgain) {
      this.isSortedAgain = param

      if (this.selectedSort === 'asc'){
        this.selectedSort = 'desc'
        this.toggleSort = !this.toggleSort
      } else if (this.selectedSort === 'desc'){
        this.selectedSort = ''
        this.toggleSort = !this.toggleSort
      } else {
        this.selectedSort = 'asc'
      }
    } else {
      this.isSortedAgain = param
      this.selectedSort = 'asc'
      this.toggleSort = true
    }

    if (this.selectedSort === 'asc' || this.selectedSort === 'desc'){
      if (this.toggleSort) {
        currentList.sort((a: any, b: any) => {          
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return -1; }
          if (textA > textB) { return 1; }
          if (textA === null) { return 1; }
          if (textB === null) { return -1; }
          return 0;
        })
      } else {
        currentList.sort((a: any, b: any) => { 
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return 1; }
          if (textA > textB) { return -1; }
          return 0;
        })
      }    
      Array.prototype.splice.apply(this.historyArray, [currentIdx, currentList.length].concat(currentList));
    }
  }

  onPageChange(event){
    this.prevPage = this.page;
    this.page = event;
    this.selectedSort = '';
    this.offset = (event - 1) * this.limit;
    this.isLoading = true;

    this.hhtApiService.getAllImageCaptureHistory(this.meterNumber, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.sortBy, this.ascDesc, this.userName).subscribe((res: any[])=>{      
      this.historyArray= res['body']['results'];
      console.log(this.historyArray)
      this.isLoading = false;
    })
  }

  monthChanged(){
    this.isLoading = true;
    this.hhtApiService.getAllImageCaptureHistory(this.meterNumber, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.sortBy, this.ascDesc, this.userName).subscribe((res: any[])=>{      
        this.historyArray= res['body']['results'];
        console.log(res['body']['results'])
        this.totalCount= res['body']['details'][0]['total_count'];
  
        this.isLoading = false;
    })
  }

  yearChanged(){
    this.isLoading = true;
    this.hhtApiService.getAllImageCaptureHistory(this.meterNumber, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.sortBy, this.ascDesc, this.userName).subscribe((res: any[])=>{      
        this.historyArray= res['body']['results'];
        console.log(res['body']['results'])
        this.totalCount= res['body']['details'][0]['total_count'];
  
        this.isLoading = false;
    })
  }

  viewImage(meter){
    console.log(meter)
    this.modalService.openCarousel(meter.meter_number, meter.consumption_type, meter.meter_read_timestamp).result.then(() => {
      console.log('TRUE')
    }).catch(() => {
      console.log('FALSE')
    });
  }

  isSortedBy(col: string) {
    if (this.sortBy === col && this.ascDesc) {
      return this.ascDesc;
    } else {
      return null;
    }
  }

  onSortFn(col) {
    switch(this.ascDesc){
      case 'asc':
        this.ascDesc = 'desc';
      break;
      case 'desc':
        this.ascDesc = '';
      break;
      case '':
        this.ascDesc = 'asc';
      break;
    }
    if (this.sortBy !== col) {
      this.ascDesc = 'asc';
    }
    this.sortBy = col;
    this.isLoading = true;
    this.hhtApiService.getAllImageCaptureHistory(this.meterNumber, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.sortBy, this.ascDesc, this.userName).subscribe((res: any[])=>{      
        this.historyArray= res['body']['results'];
        this.isLoading = false;
    })
  }

}
