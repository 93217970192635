import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponentComponent } from '@shared/loading-component/loading-component.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { InformationComponent } from './modals/information/information.component';
import { TimedComponent } from './modals/timed/timed.component';
import { MapComponent } from './modals/map/map.component';
import { GoogleMapsModule ,GoogleMap} from '@angular/google-maps';
import { CarouselComponent } from './modals/carousel/carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectReadingCenterComponent } from './modals/select-reading-center/select-reading-center.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GoogleMapsModule,
        NgbModule,
        NgxSpinnerModule,
        MatSelectModule,
        MatFormFieldModule
    ],
    exports: [
        LoadingComponentComponent,
        ProgressBarComponent,
    ],
    declarations: [
        LoadingComponentComponent,
        ConfirmComponent,
        InformationComponent,
        TimedComponent,
        MapComponent,
        CarouselComponent,
        ProgressBarComponent,
        SelectReadingCenterComponent,
    ],
    providers: [
        DatePipe,
        GoogleMap,
    ]
})
export class SharedModule { }
