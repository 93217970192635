import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  readonly local: Storage;

  constructor() {
    this.local = window.localStorage;
  }

  public set(key: string, value: any): void {
    if (value) {
      this.local.setItem(key, JSON.stringify(value));
      return;
    }
    this.local.setItem(key, value);
  }

  public get(key: string): any {
    if (key) {
      return JSON.parse(this.local.getItem(key));
    }
    return this.local.getItem(key);
  }

  public remove(key: string): void {
    this.local.removeItem(key);
  }

  public size(): number {
    return this.local.length;
  }

  public clear(): void {
    this.local.clear();
  }


}
