import { Component, OnInit } from '@angular/core';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { ModalService } from '@shared/services/modal.service';
import { Auth } from 'aws-amplify';
import { ActivatedRoute, Router } from '@angular/router';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import * as olControl from 'ol/control';

@Component({
  selector: 'inquiry-map-portal',
  templateUrl: './inquiry-map-portal.component.html',
  styleUrls: ['./inquiry-map-portal.component.scss']
})
export class InquiryMapPortalComponent implements OnInit {
  isLoading: boolean = true;

  limit = 5;
  offset= 0;

  monthOptions = [
    {
      month: 'January',
      value: '01'
    },
    {
      month: 'February',
      value: '02'
    },
    {
      month: 'March',
      value: '03'
    },
    {
      month: 'April',
      value: '04'
    },
    {
      month: 'May',
      value: '05'
    },
    {
      month: 'June',
      value: '06'
    },
    {
      month: 'July',
      value: '07'
    },
    {
      month: 'August',
      value: '08'
    },
    {
      month: 'September',
      value: '09'
    },
    {
      month: 'October',
      value: '10'
    },
    {
      month: 'November',
      value: '11'
    },
    {
      month: 'December',
      value: '12'
    }
  ]
  convertMonth:number;
  selectedMonth:string;
  yearOptions = [];
  selectedYear:string;
  prevSelectedMonth:string;
  prevSelectedYear:string;

  userAccountInfo = [];
  userEmail:string;
  userName:string;

  map;
  markerIcon = '../../../assets/img/multi-point-marker.svg';
  colors = [
    'blue',
    'red',
    'green',
    'yellow',
    'orange'
  ];
  searchableBy = [
    {
      text: 'Itinerary',
      value: 'itineraryCode'
    },
    {
      text: 'Route',
      value: 'route'
    },
    {
      text: 'Reading Center',
      value: 'readingCenter'
    },
    {
      text: 'Service Type (GP or Non-GP)',
      value: 'serviceType'
    }
  ];
  searchBy = 'itineraryCode';
  searchArrayPrev = [
    { search: '' }
  ];
  searchArray = [
    { search: '' }
  ];
  inquiryArray = [];
  vectorLayer;
  mapPadding = [60, 50, 20, 50];

  constructor(
    private hhtApiService: HhtApiService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    var d = new Date();
    var currentMonth = d.getMonth();
    this.convertMonth = (currentMonth + 1);
    var month_ = "";
    if (this.convertMonth.toString().length == 1){
      month_ = "0" + this.convertMonth.toString();
    }
    else {
      month_ = this.convertMonth.toString()
    }
    this.selectedMonth =  month_;

    var currentYear = d.getFullYear();
    var startYear = currentYear - 5;
    for(let i=0; i<6; i++){
      let yearOption = startYear + i;
      this.yearOptions.push(yearOption)
    }

    this.selectedYear = currentYear.toString();

    this.hhtApiService.getInquiryMapPortal(this.limit, this.offset, this.searchBy, this.searchArray, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{      
      this.inquiryArray= res['body']['results'];
      this.isLoading = false;
      this.loadMap();
    });
    
    Auth.currentSession().then( data => {
      this.userEmail = data.getIdToken().payload.email;
      this.userName = data.getIdToken().payload['cognito:username'];
      //this.myService.changeMessage(this.userName)
      console.log("Email :" + this.userEmail)
      console.log("UserName :" + this.userName)
      
      this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
        console.log(res['body']['UserInfo'])
        this.userAccountInfo = res['body']['UserInfo']
        
        if(this.userAccountInfo.length > 0){       
          console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
          const d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          console.log(year + '-' + month + '-' + day)

          if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

          }
          else {
            this.router.navigate(['hht/change-password'], 
            { 
            });
          }
          
        }
        else {
          console.log('Credential does not exist in db')
          this.router.navigate(['hht/change-password'], 
          { 
          });
          
        }
      })
    });
  }

  getFeatures() {
    return this.inquiryArray.map((data, index) => {
      const lonLatArray = data.Longitude_Latitude.split(",");
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat([Number(lonLatArray[0]), Number(lonLatArray[1])])),
      });
      feature.setStyle(
        new Style({
          image: new Icon({
            color: this.colors[index],
            crossOrigin: 'anonymous',
            anchor: [0.5, 1],
            src: this.markerIcon
          })
        })
      );
      return feature;
    });
  }

  loadMap() {
    this.vectorLayer = new VectorLayer({
      source: new VectorSource(),
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      target: 'map',
      view: new View({
        center: [0, 0],
        zoom: 17,
      }),
      controls: olControl.defaults({attribution: false, zoom: false})
    });

    this.vectorLayer.getSource().addFeatures(this.getFeatures());

    this.map.addLayer(this.vectorLayer);

    this.map.getView().fit(this.vectorLayer.getSource().getExtent(), {
      size: this.map.getSize(),
      padding: this.mapPadding
    });
  }

  updateMap() {
    this.vectorLayer.getSource().addFeatures(this.getFeatures());
    this.map.getView().fit(this.vectorLayer.getSource().getExtent(), {
      size: this.map.getSize(),
      padding: this.mapPadding,
      maxZoom: 17,
      duration: 1000
    });
  }

  monthChanged(month){
    this.isLoading = true;
    this.vectorLayer.getSource().clear();
    this.hhtApiService.getInquiryMapPortal(this.limit, this.offset, this.searchBy, this.searchArray, this.selectedYear, month).subscribe((res: any[])=>{      
      this.inquiryArray= res['body']['results'];
      this.isLoading = false;
      this.updateMap();
    })
  }

  yearChanged(year){
    this.isLoading = true;
    this.vectorLayer.getSource().clear();
    this.hhtApiService.getInquiryMapPortal(this.limit, this.offset, this.searchBy, this.searchArray, year, this.selectedMonth).subscribe((res: any[])=>{      
      this.inquiryArray= res['body']['results'];
      this.isLoading = false;
      this.updateMap();
    })
  }

  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  searchByChanged(searchBy) {
    const searchIsEmpty = this.searchArray.every((searchItem) => !searchItem.search);
    if (!searchIsEmpty) {
      this.isLoading = true;
      this.vectorLayer.getSource().clear();
      this.hhtApiService.getInquiryMapPortal(this.limit, this.offset, searchBy, this.searchArray, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{      
        this.inquiryArray= res['body']['results'];
        this.isLoading = false;
        this.updateMap();
      })
    }
  }

  addSearch() {
    this.searchArray.push({search: ''});
  }

  removeSearch(index) {
    this.searchArray.splice(index, 1);
  }

  searchKeyUp(index, event) {
    this.searchArray[index].search = event.target.value;
    this.isLoading = true;
    this.vectorLayer.getSource().clear();
    this.hhtApiService.getInquiryMapPortal(this.limit, this.offset, this.searchBy, this.searchArray, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{      
      this.inquiryArray= res['body']['results'];
      this.isLoading = false;
      this.updateMap();
    })
  }

}
