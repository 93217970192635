import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'; 
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { AesService } from '@shared/services/aes.service';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as AWS from 'aws-sdk';
import { InformationComponent } from '@shared/modals/information/information.component';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
    @Input() type: string;
    @Input() text: any;
    @Input() percent: any;
    @Input() color: any;
    @Input() textSize: any;
    @Input() borderSize: any;

    ngOnInit(): void {
    }

}