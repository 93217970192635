import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() information: string;
  @Input() positiveButton: string;
  @Input() negativeButton: string;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
}
