import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { StoreService } from '@app/shared/services/store.service';
import { AuthService } from '@app/shared/services/guards/auth.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'hht/itinerary-list',     
    title: 'Itineraries',         
    icon:'../../assets/img/icons/icon-itinerary.svg',       
    class: '' 
  },
  { path: 'hht/meter-list',       
    title: 'Master Meter Data',           
    icon:'../../assets/img/icons/icon-mmd.png',  
    class: '' 
  }
];


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']

})
export class AdminLayoutComponent implements OnInit {

  hideUserManagement: boolean = false;
  hideReports: boolean = false;
  hideItineraries: boolean = false;
  hideMasterMeterData: boolean = false;
  hideMaintenance: boolean = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private authService: AuthService
  ) { }

  ngOnInit() { 
    Auth.currentUserInfo().then(data =>{
      console.log('refresh');
      this.authService.assignCurrentUser(String(data['username']).replace(environment.adPrefix, '')).then(()=>{
        let currentUser = this.authService.getCurrentUser();
        this.authService.setUserIsFromAd(!!String(data['username']).includes(environment.adPrefix)); //sets the global service variable isAD to check if user logged is from AD.
        this.hideUserManagement = !['MDOA_ADMIN', 'GP_DATA_STATION_OFFICER', 
                                    'NONGP_DATA_STATION_OFFICER', 
                                    'DMSA', 'SUPERUSER', 'OCR_METER_COORDINATOR', 'NON_MECO_OCR_METER_COORDINATOR', 'METER_COORDINATOR'].includes(currentUser.accountType); // hide User Management section if user is not DMSA/SuperUser and is from AD
        this.hideReports = ['MDOA_STAFF', 'DMSA', 'METER_READER', 'OCR_METER_READER', 'OCR_METER_COORDINATOR',
                            'NON_MECO_OCR_METER_COORDINATOR'].includes(currentUser.accountType); //hide Reports if user is MDOA STAFF or DMSA
        this.hideItineraries = ['METER_READER', 'OCR_METER_READER'].includes(currentUser.accountType); //hide Itineraries if user is METER READER
        this.hideMasterMeterData = ['METER_READER', 'OCR_METER_READER'].includes(currentUser.accountType); //hide Master Meter Data if user is METER READER
        this.hideMaintenance = ['MDOA_STAFF', 'METER_READER', 'OCR_METER_READER'].includes(currentUser.accountType); //hide Maintenance if user is METER READER
      });
    })
  }


  itenerariesOnClick() {
    console.log('itenerariesOnClick')
    this.router.navigate(['hht/i'])
  }

  masterMeterDataOnClick() {
    this.router.navigate(['hht/mmd'])
  }

  itenerariesListOnClick(){
    this.router.navigate(['hht/il'])
  }

  onLogOut() {
    Auth.signOut()
    localStorage.clear();
    window.location.replace(environment.signOut);
    this.storeService.clear();
  }
}
