import { Component, OnInit, Input } from '@angular/core';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { StoreService } from '@app/shared/services/store.service';

import { ActivatedRoute, Router } from '@angular/router';
import { HhtApiService } from '@app/shared/services/hht-api.service';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    iconAtv: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: 'hht/itinerary-list',     title: 'Itineraries',         icon:'../../assets/img/icons/icon-itinerary.svg', iconAtv:'../../assets/img/icons/icon-itinerary.svg',       class: '' },
    { path: 'hht/meter-list',       title: 'Master Meter Data',           icon:'../../assets/img/icons/icon-mmd.png', iconAtv:'../../assets/img/icons/icon-mmd.png',  class: '' },

    { path: '',       title: '',   icon:'', iconAtv:'',  class: 'bottom-divider' },
    { path: 'hht/online-meter-reading/maintenance',       title: 'Maintenance',   icon:'../../assets/img/icons/Password.png', iconAtv:'../../assets/img/icons/Password.png',  class: '' },
    { path: 'hht/user-management',       title: 'User Management',   icon:'../../../../assets/img/icons/icon-users.svg', iconAtv:'../../../../assets/img/icons/icon-users.svg',  class: '' },
    { path: 'hht/reports',       title: 'Reports',   icon:'../../../../assets/img/icons/reportSelected.svg', iconAtv:'../../../../assets/img/icons/reportSelected.svg',  class: '' },
    { path: '',       title: '',   icon:'', iconAtv:'',  class: 'bottom-divider' },
    { path: 'hht/change-password',       title: 'Account Settings',   icon:'../../../../assets/img/icons/icon-account-settings.svg', iconAtv:'../../../../assets/img/icons/icon-account-settings.svg',  class: '' },
];

//ROUTES.push(
//    { path: 'hht/meter-list',       title: 'Master Meter Data',           icon:'../../assets/img/icons/icon-mmd.png', iconAtv:'../../assets/img/icons/icon-mmd-selected.svg',  class: '' },
//)


@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    cognitoUserName:string;
    public menuItems: any[];
    appVersion = environment.app_version;

    isCollapsed: boolean = true;

    @Input()
    hideItineraries: boolean = false;

    @Input()
    hideMasterMeterData: boolean = false;

    @Input()
    hideMaintenance: boolean = false;

    @Input()
    hideUserManagement: boolean = false;

    @Input()
    hideReports: boolean = false;

    constructor(private storeService: StoreService,
        private router: Router, 
        private route: ActivatedRoute,
        private hhtApiService: HhtApiService){

    }
    

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.isCollapsed = !['/hht/online-meter-reading/maintenance', '/hht/online-meter-reading'].includes(this.router.url);
    }

     

    onLogOut() {

        console.log('signOut')
        // Auth.signOut({ global: true })
        // // this.storeService.clear();
        // window.location.replace(environment.signOut);
        Auth.currentSession().then( data => {
            console.log('onLogOut()',data.getIdToken().payload['cognito:username'])
            this.cognitoUserName = data.getIdToken().payload['cognito:username'];
            const body = {
                username: this.cognitoUserName
              }
            this.hhtApiService.deleteSession(body).subscribe(res => {
                Auth.signOut({ global: true })
                localStorage.clear();
                this.storeService.clear();
                window.location.replace(environment.signOut);
            })
        })
        

    }

    
    onChangePassword(){
        this.router.navigate(['hht/change-password'], 
        { 
        //relativeTo: this.route,
        });
    }

    onMeterReading(){
        this.router.navigate(['hht/online-meter-reading'], 
        { 
        //relativeTo: this.route,
        });
    }

    onMeterReadingMaintenance(){
        this.router.navigate(['hht/online-meter-reading/maintenance'], 
        { 
        //relativeTo: this.route,
        });
    }
}
