import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercase-only]'
})
export class UppercaseOnlyDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event']) 
  onInputChange(event) {
		const initalValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initalValue.replace(/[^A-Za-z]*/g, '');
		if (initalValue !== this.el.nativeElement.value) {
			event.stopPropagation();
		}else{
      this.el.nativeElement.value = String(this.el.nativeElement.value).toUpperCase();
    }
    this.ngControl.control.setValue(this.el.nativeElement.value);
	}

}
