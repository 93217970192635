import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { JwtHelperService } from "@auth0/angular-jwt";
import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { StoreService } from '@app/shared/services/store.service';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationComponent } from '@shared/modals/information/information.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  myVariable: any;
  currentUser: any = {
    email: '',
    accountType: '',
    readingCenterCode: '',
    isAD: false,
    firstName: '',
    lastName: '',
    expirationDate: '',
    creationDate: ''
  }
  constructor(private hhtApiService: HhtApiService,
              private storeService: StoreService,
              private modal: NgbModal) { }

  public isAuthenticated(): Promise<boolean> {
    return this.retrieveIdToken()
      .then(token => {
        // console.log(token);
        const helper = new JwtHelperService();
        return token !== null && !helper.isTokenExpired(token);
      })
      .catch(() => {
        console.log("no session")
        return false;
      });
  }

  public retrieveIdToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(data => {
          if (data.isValid()){
            resolve(data.getIdToken().getJwtToken());
          }
          else{
            reject({message: "Session Invalid."});
          }
        })
        .catch(err => reject({message: "Session Invalid."}));
    });
  }

  public isVerified(){
    return from(Auth.currentAuthenticatedUser())
  }

  public currentSession(){
    return from(Auth.currentSession());
  }

  public currentUserInfo(){
    return from(Auth.currentUserInfo());
  }

  public signouttest(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Auth.signOut({global: true}) ){
        resolve(true)
      }{
        reject(true)
      }
    })
  }

  public setCurrentUser(email, accountType, readingCenterCode, firstName, lastName, expirationDate, creationDate){
    this.currentUser.email = email;
    this.currentUser.accountType = accountType;
    this.currentUser.readingCenterCode = readingCenterCode;
    this.currentUser.firstName = firstName;
    this.currentUser.lastName = lastName;
    this.currentUser.expirationDate = expirationDate;
    this.currentUser.creationDate = creationDate;
  }

  public getCurrentUser(){
    return this.currentUser;
  }

  public setUserIsFromAd(flag){
    this.currentUser.isAD = flag;
  }

  public async assignCurrentUser(username): Promise<boolean>{
    let params = { email: username, userType: '' };
    this.setUserIsFromAd(String(username).split('@')[1] == 'meralco.com.ph')  
    let res = await this.hhtApiService.getReaderFFCodes(params).toPromise(); //reader code is the endpoint for users since users and reader codes merged as one
    let result = res['body']['results'];
    if(result.length > 0){
      this.setCurrentUser(username, result[0].accountType, result[0].readingCenterCodes, result[0].first_name, result[0].last_name, result[0].expirationDate, result[0].date_created);
      // let username_ = ""
      // if  (this.currentUser.isAD == true){
      //   username_ = String(environment.adPrefix) + username
      // }
      

      // this.hhtApiService.get_websession_token(username_).subscribe((res: any[])=>{    
      //   //console.log("res1",sessionStorage.getItem('token'))
      //   //console.log("res2",res["body"]["results"][0]["jwttoken"]);
      //   //console.log("test2",res["body"]["results"].length);
      //   if (res["body"]["results"].length == 0){
      //     console.log("1")
      //     console.log("no token in db - closing session")
      //     alert('Your account is logged in from different session. Press OK to reload the page and reauthenticate.')
      //     Auth.signOut()
      //     localStorage.clear();
      //     window.location.replace(environment.signOut);
      //   }
      //   else if (res["body"]["results"][0]["jwttoken"] != sessionStorage.getItem('token')){
      //     console.log("2")
      //     console.log("token not match - closing session")
      //     alert('Your account is logged in from different session. Press OK to reload the page and reauthenticate.')
      //     Auth.signOut()
      //     localStorage.clear();
      //     window.location.replace(environment.signOut);
        
      //     // const body = {
      //     //     username: username_
      //     //   }
      //     // this.hhtApiService.deleteSession(body).subscribe(res => {
      //     //     Auth.signOut()
      //     //     localStorage.clear();
      //     //     window.location.replace(environment.signOut);
      //     // })
      //   }
      //   else {
      //     console.log("3")
      //     console.log("token match")
      //   }
      // })

      return true;
    }else{
      console.log('Current logged in user does not exist in the database'); // temporary, needs more context on how to handle this scenario             
      const modalRef = this.modal.open(InformationComponent);
      modalRef.componentInstance.header = 'Information';
      modalRef.componentInstance.information = 'You are not yet registered. Logging out.'
      modalRef.componentInstance.positiveButton = 'Close';
      modalRef.result.then(result =>{
        this.logOut();
      })
    }
  }

  logOut(){
    Auth.currentSession().then( data => {
      const body = {
          username: data.getIdToken().payload['cognito:username']
        }
      this.hhtApiService.deleteSession(body).subscribe(res => {
          Auth.signOut()
          localStorage.clear();
          this.storeService.clear();
          window.location.replace(environment.signOut);
      })
  })
  }
}