import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-reading-center',
  templateUrl: './select-reading-center.component.html',
  styleUrls: ['./select-reading-center.component.scss']
})
export class SelectReadingCenterComponent implements OnInit {
  @Input() readingCenters: Array<string>;
  @Input() email: string;
  @Input() afterSaving: () => void;

  selectedReadingCenter: string;
  
  constructor(
    public activeModal: NgbActiveModal,
    private hhtApiService: HhtApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.selectedReadingCenter = this.readingCenters[0];
  }

  onClickSave() {
    const requestPayload = {
      email: this.email,
      readingCenterCode: this.selectedReadingCenter
    };
    this.hhtApiService.saveReadingCenterByEmail(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        console.log('Result', res);
                        this.activeModal.close();
                        this.toastr.success(`Your selected Reading Center is ${this.selectedReadingCenter}`, 'Changes Saved', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                        this.afterSaving();
                      },
                      error=>{
                        console.error('Error', error);
                        this.toastr.success(error['message'], 'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      });
  }
}
