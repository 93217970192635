import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewEncapsulation } from '@angular/core';

import 'ol/ol.css';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectoreSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Overlay from 'ol/Overlay';
import * as olProj from 'ol/proj';
import * as olControl from 'ol/control';
import {unByKey} from 'ol/Observable';
import OverlayPositioning from 'ol/OverlayPositioning';

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-itinerary-map',
  templateUrl: './itinerary-map.component.html',
  styleUrls: ['./itinerary-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ItineraryMapComponent implements OnInit, OnChanges {
  detailsIsCollapsed = true;
  isCollapsed = true;

  public readingPositions: any = [];
  public readingLocationMarkers: any = [];
  public readingPosition: any;
  public readingLocationMarker: any;
  public meterPositions: any = [];
  public meterLocationMarkers: any = [];
  public meterPosition: any;
  public meterLocationMarker: any;
  public redIconTransUrl = '../../../assets/img/red-marker-inactive.svg';
  public blueIconTransUrl = '../../../assets/img/blue-marker-inactive.svg';
  public redIconUrl = '../../../assets/img/red-marker-active.svg';
  public blueIconUrl = '../../../assets/img/blue-marker-active.svg';
  center;
  centerXY;

  tempListRedMarkers:any = [];
  tempListBlueMarkers:any = [];
  map;
  redMarkers: any;
  blueMarkers: any;
  redMarker: any;
  blueMarker: any;
  popUpInactive;
  popUpRedInactive;
  popUpBlueInactive;
  popUpActive;
  popUpRedActive;
  popUpBlueActive;

  meterNumber: string;
  reading: string;
  consumptionType: string;
  meterFieldFinding: string;
  actualReadingDate: string;
  actualReadingLongitude: string;
  actualReadingLatitude: string;
  longitude: string;
  latitude: string;

  activeMeterNumber: string;
  activeReading: string;
  activeConsumptionType: string;
  activeMeterFieldFinding: string;
  activeActualReadingDate: string;
  activeActualReadingLongitude: string;
  activeActualReadingLatitude: string;
  activeLongitude: string;
  activeLatitude: string;

  isMeterAndActualLocationSame:boolean;
  markerIsClicked:boolean;
  locationOptions: string[] = ["Default Meter Location", "Actual Reading Location", "All Locations"];
  selectedLocation: string = "Default Meter Location";
  meterSelected: boolean;
  deselectMeter: boolean;
  moveEndKey: any;
  isSingleClick: boolean;
  clickedMarkerCenter;
  isMapLoaded: boolean = false;
  isFilterToggled: boolean = false;
  private KH_TYPE = "KH - Kilowatthour";
  private RH_TYPE = "RH - Reactive";
  private DM_TYPE = "DM - Demand";
  private RQ_TYPE = "RQ - Reactive-Q Meter"; 
  private CK_TYPE = "CK - Check KWH";
  private TD_TYPE = "TD - Totalizer Demand";
  private CM_TYPE = "CM - Check Meter";
  private CQ_TYPE = "CQ - Check Reactive-Q";
  private CR_TYPE = "CR - Check Reactive";
  private CD_TYPE = "CD - Cumulative Demand";
  private DO_TYPE = "DO - Weekday Off Peak";
  private DP_TYPE = "DP - Weekday Peak";
  private EO_TYPE = "EO - Weekend Off Peak";
  private EP_TYPE = "EP - Weekend Peak";
  private EX_TYPE = "EX - Export Kilowatthour";

  listOfMeters:any;

  searchText: string = '';
  filteredListOfMeters: Array<any> = [];

  @Input() list: any;

  constructor(
    private datePipe: DatePipe
  ) {
   }

   //detects changes from list input
  ngOnChanges(changes: SimpleChanges){
    // console.log('ngOnChanges()')
    // console.log('changes:',changes)
    for (const propName in changes) {
      const change = changes[propName];   
      // console.log('change.currentValue:',change.currentValue)  
      if(typeof change.currentValue === "object"){
        this.listOfMeters = this.getUniqueMeterNumber(change.currentValue);
        if(this.list.length && this.listOfMeters.length>0){
          console.log('ngOnChange_loadMap')
          this.plotMap(this.listOfMeters);
          this.loadMap();
          this.isMapLoaded = true;
        }else{
          console.log('changes - no meter data');
        }
        this.filteredListOfMeters = this.listOfMeters;      
      }  
    }
  }

  ngOnInit() {
    this.selectedLocation = "Default Meter Location";
    this.isMeterAndActualLocationSame = true;
    this.markerIsClicked = false;
    this.meterSelected = false;
    this.deselectMeter = false;
    //this.isSingleClick = false;
    console.log('----->')
    console.log(this.isMapLoaded)
    console.log(this.list)
    console.log('----->')
    if(!this.isMapLoaded && typeof this.list === "object"){
      console.log('loadmap');
      console.log('lists',this.list);
      this.listOfMeters = this.getUniqueMeterNumber(this.list);
      console.log('listOfmeter',this.listOfMeters);
      if(this.list.length && this.listOfMeters.length>0){
        console.log('ngOnInit_loadMap')
        this.plotMap(this.listOfMeters);
        this.loadMap();
        this.isMapLoaded = true;
      }else{
        console.log('init - no meter data');
      } 
      this.filteredListOfMeters = this.listOfMeters;       
    }
    console.log('exitloadmap');
  }

  loadMap(){
    // console.log('loadMap()')
    const containerPopupInactive = document.getElementById('popup-inactive');
    const containerPopupRedInactive = document.getElementById('popup-red-inactive');
    const containerPopupBlueInactive = document.getElementById('popup-blue-inactive');
    const containerPopupActive = document.getElementById('popup-active');
    const containerPopupRedActive = document.getElementById('popup-red-active');
    const containerPopupBlueActive = document.getElementById('popup-blue-active');
   
    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }) ],
      target: 'itinerary-map',
      view: new View({
        center: olProj.fromLonLat([Number(this.center.lng), Number(this.center.lat)]),
        zoom: 12,
      }),
      controls: olControl.defaults({attribution: false}).extend([new olControl.FullScreen(
        {
          source: 'location-container'
        }
      )])
    });



    //define but dont load yet
    this.redMarkers = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.redIconTransUrl
        })
      }),
      className: 'red-markers',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    //define but dont load yet
    this.blueMarkers = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.blueIconTransUrl
        })
      }),
      className: 'blue-markers',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });
    
    this.map.addLayer(this.redMarkers);
    this.map.addLayer(this.blueMarkers);   

    //initially, setup blue markers only
    this.setUpInactiveBlueMarkers();
    
    //define but dont load yet
    //when blue and red marker has same coordinates
    this.popUpInactive = new Overlay({
        element: containerPopupInactive,
        positioning: OverlayPositioning.CENTER_CENTER
    });
    //define but dont load yet
    this.popUpRedInactive = new Overlay({
      element: containerPopupRedInactive,
      positioning: OverlayPositioning.CENTER_CENTER
    });
    //define but dont load yet
    this.popUpBlueInactive = new Overlay({
      element: containerPopupBlueInactive,
      positioning: OverlayPositioning.CENTER_CENTER
    });
    //define but dont load yet
    this.popUpRedActive = new Overlay({
      element: containerPopupRedActive,
    });
    //define but dont load yet
    this.popUpBlueActive = new Overlay({
      element: containerPopupBlueActive,
    });
    //define but dont load yet
    //when blue and red marker has same coordinates
    this.popUpActive = new Overlay({
      element: containerPopupActive,
    });
    
    this.map.addOverlay(this.popUpInactive);
    this.map.addOverlay(this.popUpRedInactive);
    this.map.addOverlay(this.popUpBlueInactive);
    this.map.addOverlay(this.popUpRedActive);
    this.map.addOverlay(this.popUpBlueActive);
    this.map.addOverlay(this.popUpActive);

    this.map.on('pointermove', (event) => {
      this.popUpInactive.setPosition(undefined);
      this.popUpInactive.setOffset([0,0]);
      this.popUpBlueInactive.setPosition(undefined);
      this.popUpBlueInactive.setOffset([0,0]);
      this.popUpRedInactive.setPosition(undefined);
      this.popUpRedInactive.setOffset([0,0]);
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
            if(feature.get('name')==='Red Markers' || feature.get('name')==='Blue Markers'){           
              const returnCoord = feature.get('geometry').flatCoordinates;

              this.meterNumber = feature.getProperties().meterNumber;
              this.reading = feature.getProperties().reading;
              this.consumptionType = feature.getProperties().meterConsumptionType;
              this.meterFieldFinding = feature.getProperties().meterFieldFinding;
              this.actualReadingDate = this.datePipe.transform(feature.getProperties().meterActualReading, 'MM/dd/yyyy - h:mm:ss a');
              this.longitude = feature.getProperties().longitude;
              this.latitude = feature.getProperties().latitude;
              this.actualReadingLatitude = feature.getProperties().actualReadingLatitude;
              this.actualReadingLongitude = feature.getProperties().actualReadingLongitude;

              if(this.selectedLocation==="Default Meter Location" || (this.selectedLocation==="All Locations" && feature.get('name')==='Blue Markers')){
                this.popUpBlueInactive.setPosition(returnCoord);
                this.popUpBlueInactive.setOffset([0,-40]);
              }else if(this.selectedLocation==="Actual Reading Location" || (this.selectedLocation==="All Locations" && feature.get('name')==='Red Markers')){
                this.popUpRedInactive.setPosition(returnCoord);
                this.popUpRedInactive.setOffset([0,-40]);
              }else if(this.selectedLocation==="All Locations"){
                this.popUpInactive.setPosition(returnCoord);
                this.popUpInactive.setOffset([0,-40]);
              }
            }
        })
    });
  }   

  plotMap(list:any){
    // console.log('plotMap()')
    if(typeof list === "undefined" || (typeof list === "object" && list.length==0)){
      return
    }

    this.setUpMarkerPositions(list);
    
    for(let pos of this.meterPositions){
      if(!isNaN(Number(pos.latitude)) && !isNaN(Number(pos.longitude)) && Number(pos.latitude)!==0 && Number(pos.longitude)!==0){
        this.center = {
          lat: pos.latitude,
          lng: pos.longitude
        }
        break;
      }
    }
  }

  setUpMarkerPositions(list){
    // console.log('setUpMarkerPositions()')
    // console.log(list)
    this.readingPositions = list
    .filter(pos => pos.actual_reading_latitude!==0 && pos.actual_reading_longitude!==0)
    .map(data => { 
      return {
        latitude: data.latitude,
        longitude: data.longitude,
        actualReadingLatitude: data.actual_reading_latitude,
        actualReadingLongitude: data.actual_reading_longitude,
        meterNumber: data.meter_number,
        meterConsumptionType: data.meter_consumption_type,
        meterFieldFinding: data.meter_field_finding,
        meterActualReading: data.meter_read_timestamp,
        reading: data.readings
      }
    });

    this.readingPositions = this.getUniqueCoords(this.readingPositions.map(data =>({
      ...data,
      lat: data.actualReadingLatitude,
      lng: data.actualReadingLongitude
    })));

    this.meterPositions = list
    .filter(pos => pos.latitude!==0 && pos.longitude!==0)
    .map(data => { 
      return {
        latitude: data.latitude,
        longitude: data.longitude,
        actualReadingLatitude: data.actual_reading_latitude,
        actualReadingLongitude: data.actual_reading_longitude,
        meterNumber: data.meter_number,
        meterConsumptionType: data.meter_consumption_type,
        meterFieldFinding: data.meter_field_finding,
        meterActualReading: "",
        reading: data.readings
      }
    });

    this.meterPositions = this.getUniqueCoords(this.meterPositions.map(data =>({
      ...data,
      lng: data.longitude,
      lat: data.latitude
    })));

    // console.log("readingPositions", this.readingPositions);
    // console.log("meterPositions", this.meterPositions);
  }

  setUpInactiveRedMarkers(){
    // console.log('setUpInactiveRedMarkers()')
    for(let m of this.readingPositions){
      let position = [m.actualReadingLongitude , m.actualReadingLatitude];
      const marker = new Feature({
        geometry: new Point(olProj.fromLonLat(position)),
        name: 'Red Markers',
        longitude: m.longitude,
        latitude: m.latitude,
        actualReadingLongitude: m.actualReadingLongitude,
        actualReadingLatitude: m.actualReadingLatitude,
        meterNumber: m.meterNumber,
        meterConsumptionType: m.meterConsumptionType,
        meterFieldFinding: m.meterFieldFinding,
        meterActualReading: m.meterActualReading,
        reading: m.reading
      });    
      this.redMarkers.getSource().addFeature(marker);
    }
  }

  setUpInactiveBlueMarkers(){
    // console.log('setUpInactiveBlueMarkers()')
    for(let m of this.meterPositions){
      let position = [m.longitude, m.latitude];
      const marker = new Feature({
        geometry: new Point(olProj.fromLonLat(position)),
        name: 'Blue Markers',
        longitude: m.longitude,
        latitude: m.latitude,
        actualReadingLongitude: m.actualReadingLongitude,
        actualReadingLatitude: m.actualReadingLatitude,
        meterNumber: m.meterNumber,
        meterConsumptionType: m.meterConsumptionType,
        meterFieldFinding: m.meterFieldFinding,
        meterActualReading: m.meterActualReading,
        reading: m.reading
      });  
      this.blueMarkers.getSource().addFeature(marker);
    }
  }

  changeLocation(location){
    // console.log('changeLocation()')
    this.selectedLocation = location;
    //remove red and blue markers    
    this.removeInactiveRedAndBlueMarkers();

    //add layer or layers as per selected option
    if(this.selectedLocation==="Actual Reading Location" && !this.meterSelected){ 
      this.redMarkers.getSource().clear();    
      this.map.addLayer(this.redMarkers);   
      this.setUpInactiveRedMarkers();

    }else if(this.selectedLocation==="Default Meter Location" && !this.meterSelected){
      this.blueMarkers.getSource().clear(); 
      this.map.addLayer(this.blueMarkers);
      this.setUpInactiveBlueMarkers();

    }else if(this.selectedLocation==="All Locations" && !this.meterSelected){
      this.redMarkers.getSource().clear();
      this.blueMarkers.getSource().clear();            
      this.map.addLayer(this.redMarkers);      
      this.map.addLayer(this.blueMarkers);
      
      this.setUpInactiveRedMarkers();
      this.setUpInactiveBlueMarkers();
    }

  }

  removeInactiveRedAndBlueMarkers(){
    // console.log('removeInactiveRedAndBlueMarkers()')
    this.map.getLayers().forEach((layer, index) => {
      if(layer!== undefined && layer.className_ === 'red-markers'){
        this.map.getLayers().removeAt(index);
      }    
    });
    this.map.getLayers().forEach((layer, index) => {
      if(layer!== undefined && layer.className_ === 'blue-markers'){
        this.map.getLayers().removeAt(index);
      }     
    });
  }

  closeActivePopup(){
    this.popUpActive.setPosition(undefined);
  }
  closeRedActivePopup(){
    this.popUpRedActive.setPosition(undefined);
  }
  closeBlueActivePopup(){
    this.popUpBlueActive.setPosition(undefined);
  }

  checkSelectedMeter(event){
    // console.log('checkSelectedMeter()')
    const actualReadingLongitude = Number(event.actual_reading_longitude);
    const actualReadingLatitude = Number(event.actual_reading_latitude);
    const longitude = Number(event.longitude);
    const latitude = Number(event.latitude);
    this.meterSelected = true;
    this.deselectMeter = false;
    //unByKey(this.moveEndKey);

    this.isMeterAndActualLocationSame = event.actual_reading_longitude===event.longitude && event.actual_reading_latitude===event.latitude;
    
    this.activeActualReadingLongitude = event.actual_reading_longitude;
    this.activeActualReadingLatitude = event.actual_reading_latitude;
    this.activeLongitude = event.longitude;
    this.activeLatitude = event.latitude;
    this.activeMeterNumber = event.meter_number;
    this.activeConsumptionType = event.meter_consumption_type;
    this.activeMeterFieldFinding = event.meter_field_finding;
    this.activeActualReadingDate = this.datePipe.transform(event.meter_read_timestamp, 'MM/dd/yyyy - h:mm:ss a');
    this.activeReading = event.readings;
    
    this.removeInactiveRedAndBlueMarkers();

    if(actualReadingLatitude!==0 && actualReadingLongitude!==0){

      this.popUpRedActive.setPosition(undefined);
      this.popUpRedActive.setOffset([0,0]);
      this.readingPosition = {
        lat: event.actual_reading_latitude,
        lng: event.actual_reading_longitude
      };

      this.map.getLayers().forEach((layer, index) => {
        if(layer!== undefined && layer.className_ === 'red-marker'){
          this.map.getLayers().removeAt(index);
        }     
      });

      if(this.tempListRedMarkers.length == 0){
        this.tempListRedMarkers = this.readingPositions;
        this.readingPositions = this.readingPositions.filter(data => 
        data.actualReadingLatitude !== this.readingPosition.lat &&
        data.actualReadingLongitude !== this.readingPosition.lng);
      }else{
        this.readingPositions = this.tempListRedMarkers.filter(data => 
          data.actualReadingLatitude !== this.readingPosition.lat &&
          data.actualReadingLongitude !== this.readingPosition.lng);
      }

      this.redMarker = new VectorLayer({
        source: new VectoreSource(),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: this.redIconUrl
          })
        }),
        className: 'red-marker'
      });

      this.readingPositions = this.readingPositions.filter(data => data.actualReadingLatitude!==this.readingPosition.lat && data.actualReadingLongitude!==this.readingPosition.lng);

      this.redMarker.getSource().clear();
      const singleMarker = new Feature({
        geometry: new Point(olProj.fromLonLat([this.readingPosition.lng, this.readingPosition.lat])),
        name: 'Red Marker',
        longitude: this.activeLongitude,
        latitude: this.activeLatitude,
        actualReadingLongitude: this.activeActualReadingLongitude,
        actualReadingLatitude: this.activeActualReadingLatitude,
        meterNumber: this.activeMeterNumber,
        meterConsumptionType: this.activeConsumptionType,
        meterFieldFinding: this.activeMeterFieldFinding,
        meterActualReading: this.activeActualReadingDate,
        reading: this.activeReading      
      }); 

      this.redMarker.getSource().addFeature(singleMarker);
      this.map.addLayer(this.redMarker);

      this.redMarkers.getSource().clear();
      if(this.selectedLocation==="Actual Reading Location" || this.selectedLocation==="All Locations"){
        this.setUpInactiveRedMarkers();
      }

      //add overlay for active red marker
      if(!this.isMeterAndActualLocationSame){        
        this.popUpRedActive.setPosition(olProj.fromLonLat([Number(this.readingPosition.lng), Number(this.readingPosition.lat)]));
        this.popUpRedActive.setOffset([0,-50]);
      }     

    }

    if(latitude!==0 && longitude!==0){

      this.popUpBlueActive.setPosition(undefined);
      this.popUpBlueActive.setOffset([0,0]);
      this.popUpActive.setPosition(undefined);
      this.popUpActive.setOffset([0,0]);
      this.meterPosition = {
        lat: event.latitude,
        lng: event.longitude
      };

      if(!isNaN(latitude) && !isNaN(longitude)
          && latitude!==0 && longitude!==0){
        this.center = {
          lat: this.meterPosition.lat,
          lng: this.meterPosition.lng
        };
      }
      
      //set map center     
      this.map.getView().setCenter(olProj.fromLonLat([Number(this.center.lng), Number(this.center.lat)]));

      this.map.getLayers().forEach((layer, index) => {
        if(layer!== undefined && layer.className_ === 'blue-marker'){
          this.map.getLayers().removeAt(index);
        }      
      });    

      if(this.tempListBlueMarkers.length == 0){
        this.tempListBlueMarkers = this.meterPositions;
        this.meterPositions = this.meterPositions.filter(data =>
          data.latitude !== this.meterPosition.lat &&
          data.longitude !== this.meterPosition.lng);
      }else{
        this.meterPositions = this.tempListBlueMarkers.filter(data =>
          data.latitude !== this.meterPosition.lat &&
          data.longitude !== this.meterPosition.lng);
      }

      this.blueMarker = new VectorLayer({
        source: new VectoreSource(),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: this.blueIconUrl
          })
        }),
        className: 'blue-marker'
      });

      this.meterPositions = this.meterPositions.filter(data => data.latitude!==this.meterPosition.lat && data.longitude!==this.meterPosition.lng);
    
      this.blueMarker.getSource().clear();
      const singleMarker = new Feature({
        geometry: new Point(olProj.fromLonLat([this.meterPosition.lng, this.meterPosition.lat])),
        name: 'Blue Marker',
        longitude: this.activeLongitude,
        latitude: this.activeLatitude,
        actualReadingLongitude: this.activeActualReadingLongitude,
        actualReadingLatitude: this.activeActualReadingLatitude,
        meterNumber: this.activeMeterNumber,
        meterConsumptionType: this.activeConsumptionType,
        meterFieldFinding: this.activeMeterFieldFinding,
        meterActualReading: this.activeActualReadingDate,
        reading: this.activeReading
      }); 

      this.blueMarker.getSource().addFeature(singleMarker);
      this.map.addLayer(this.blueMarker);

      this.blueMarkers.getSource().clear();
      if(this.selectedLocation==="Default Meter Location" || this.selectedLocation==="All Locations"){
        this.setUpInactiveBlueMarkers();
      }

      //add overlay for active blue marker
      if(!this.isMeterAndActualLocationSame){        
        this.popUpBlueActive.setPosition(olProj.fromLonLat([Number(this.meterPosition.lng), Number(this.meterPosition.lat)]));
        this.popUpBlueActive.setOffset([0,-50]);
      }else{       
        this.popUpActive.setPosition(olProj.fromLonLat([Number(this.meterPosition.lng), Number(this.meterPosition.lat)]));
        this.popUpActive.setOffset([0,-50]);
      }     
    }

    this.map.getView().setZoom(20);

    //event to show info window of active marker if info window was closed
    this.map.on('singleclick', (event)=>{
      //this.isSingleClick = false;     
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if(feature.get('name')==='Red Marker' || feature.get('name')==='Blue Marker'){
          // console.log('marker properties', feature.getProperties())
          this.activeActualReadingLongitude = feature.getProperties().actualReadingLongitude;
          this.activeActualReadingLatitude = feature.getProperties().actualReadingLatitude;
          this.activeLongitude = feature.getProperties().longitude;
          this.activeLatitude = feature.getProperties().latitude;
          this.activeMeterNumber = feature.getProperties().meterNumber;
          this.activeConsumptionType = feature.getProperties().meterConsumptionType;
          this.activeMeterFieldFinding = feature.getProperties().meterFieldFinding;
          this.activeActualReadingDate = feature.getProperties().meterActualReading;
          this.activeReading = feature.getProperties().reading;
          if(this.isMeterAndActualLocationSame){
            if(this.popUpActive.getPosition()===undefined){
              this.popUpActive.setPosition(olProj.fromLonLat([Number(this.activeLongitude), Number(this.activeLatitude)]));
              this.popUpActive.setOffset([0,-50]);
            }            
            this.clickedMarkerCenter = olProj.fromLonLat([Number(this.activeLongitude), Number(this.activeLatitude)]);
          }else if(feature.get('name')==='Red Marker'){
            if(this.popUpRedActive.getPosition()===undefined){
              this.popUpRedActive.setPosition(olProj.fromLonLat([Number(this.activeActualReadingLongitude), Number(this.activeActualReadingLatitude)]));
              this.popUpRedActive.setOffset([0,-50]);
            }                      
            this.clickedMarkerCenter = olProj.fromLonLat([Number(this.activeActualReadingLongitude), Number(this.activeActualReadingLatitude)]);
          }else if(feature.get('name')==='Blue Marker'){
            if(this.popUpBlueActive.getPosition()===undefined){
              this.popUpBlueActive.setPosition(olProj.fromLonLat([Number(this.activeLongitude), Number(this.activeLatitude)]));
              this.popUpBlueActive.setOffset([0,-50]);
            }                       
            this.clickedMarkerCenter = olProj.fromLonLat([Number(this.activeLongitude), Number(this.activeLatitude)]);
          }
          
          //this.isSingleClick = true;
          this.map.getView().setCenter(this.clickedMarkerCenter);
          
        }
      })
    });

    //execute first before double click event. moveEndKey is used inside double click event
    /**this.moveEndKey = this.map.on('moveend', () => {
      if(this.isSingleClick){
        this.map.getView().setCenter(this.clickedMarkerCenter);
        this.isSingleClick = false;
        unByKey(this.moveEndKey);
      }else{
        this.map.getView().setCenter(olProj.fromLonLat([Number(this.center.lng), Number(this.center.lat)]));
      }     
    });*/

    //event to deselect meter number
    this.map.on('dblclick', (event)=>{     
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if(feature.get('name')==='Red Marker' || feature.get('name')==='Blue Marker'){
          this.meterSelected = false;
          this.deselectMeter = true;         
          this.map.getLayers().forEach((layer, index) => {
            if(layer!== undefined && layer.className_ === 'red-marker'){
              this.map.getLayers().removeAt(index);
            }     
          });
          this.map.getLayers().forEach((layer, index) => {
            if(layer!== undefined && layer.className_ === 'blue-marker'){
              this.map.getLayers().removeAt(index);
            }     
          });
          this.filteredListOfMeters = this.listOfMeters.filter(meter =>{
            return meter.meter_number?.includes(this.searchText);
          });  
          this.setUpMarkerPositions(this.filteredListOfMeters);
          this.changeLocation(this.selectedLocation);
          this.closeActivePopup();
          this.closeRedActivePopup();
          this.closeBlueActivePopup();

          //unByKey(this.moveEndKey);
        }
      })
    });
   
  }

  getUniqueCoords(list:any): any{
    // console.log('getUniqueCoords()')
    let tempList = list;
    let previousLen = 0;
    let uniqueList = [];
    
    list.forEach(value => {
      previousLen = tempList.length;
      tempList = tempList.filter(obj => obj.lat!==value.lat && obj.lng!==value.lng);

      if(previousLen!==tempList.length){
        uniqueList.push(value);
      }
    });

    uniqueList = uniqueList.filter(data => (!isNaN(Number(data.lat)) && !isNaN(Number(data.lng))) || (data.lng!=null && data.lat!=null)); 
    return uniqueList;
  }

  getUniqueMeterNumber(list: any){
    // console.log('getUniqueMeterNumber()')
    
    if(typeof list === "undefined" || (typeof list === "object" && list.length==0)){
      return
    }

    let tempUniqueMeters = [];
    let foundMeter = false;
    let tempItem = [];

    list.forEach(item => {
      foundMeter = tempUniqueMeters.some(data=> data.meter_number === item.meter_number);
      if(!foundMeter){       
        if(this.isMeterNumberSingle(item.meter_number, list)){
          // console.log("single item", item);
          tempUniqueMeters.push(item);
        }else if(this.checkConsumptionType(this.KH_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.KH_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.RH_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.RH_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.DM_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.DM_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.RQ_TYPE, item.meter_number, list)){         
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.RQ_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.CK_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.CK_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.TD_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.TD_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.CM_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.CM_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.CQ_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.CQ_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.CR_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.CR_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.CD_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.CD_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.DO_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.DO_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.DP_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.DP_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.EO_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.EO_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.EP_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.EP_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }else if(this.checkConsumptionType(this.EX_TYPE, item.meter_number, list)){
          tempItem = list.filter(data => data.meter_number === item.meter_number && data.meter_consumption_type === this.EX_TYPE);
          tempUniqueMeters.push(tempItem[0]);
        }       
        else{
          console.log(item.meter_consumption_type, ' does not exist in the array');
        }
      }
    });

    // console.log("unique list", tempUniqueMeters);
    return tempUniqueMeters;
  }

  checkConsumptionType(type, meterNumber, meterList):boolean{
    // console.log('checkConsumptionType()')
    return meterList.some(data => data.meter_number === meterNumber && data.meter_consumption_type === type);    
  }

  isMeterNumberSingle(meterNumber, list){
    // console.log('isMeterNumberSingle()')
    let tempList = [];
    tempList = list.filter(data => data.meter_number === meterNumber);
    return tempList.length === 1;
  }

  searchMeter(){
    this.deselectMeter = true;
    if(this.searchText.trim().length > 0){
      this.filteredListOfMeters = this.listOfMeters.filter(meter =>{
        return meter.meter_number?.includes(this.searchText);
      });
    }else{
      this.filteredListOfMeters = this.listOfMeters;
    }
    if(this.filteredListOfMeters.length > 0){
      this.meterSelected = false;
      this.deselectMeter = true;         
      this.map.getLayers().forEach((layer, index) => {
        if(layer!== undefined && layer.className_ === 'red-marker'){
          this.map.getLayers().removeAt(index);
        }     
      });
      this.map.getLayers().forEach((layer, index) => {
        if(layer!== undefined && layer.className_ === 'blue-marker'){
          this.map.getLayers().removeAt(index);
        }     
      });
      this.filteredListOfMeters = this.listOfMeters.filter(meter =>{
        return meter.meter_number?.includes(this.searchText);
      });  
      this.setUpMarkerPositions(this.filteredListOfMeters);
      this.changeLocation(this.selectedLocation);
      this.closeActivePopup();
      this.closeRedActivePopup();
      this.closeBlueActivePopup();

      if (this.filteredListOfMeters.length == 1) {
        console.log('this.filteredListOfMeters[0]',this.filteredListOfMeters[0]);
        // this.checkSelectedMeter(this.filteredListOfMeters[0])
        if(this.selectedLocation==="Actual Reading Location"){
          this.map.getView().setCenter(olProj.fromLonLat([Number(this.filteredListOfMeters[0].actual_reading_longitude), Number(this.filteredListOfMeters[0].actual_reading_latitude)]));  
        }
        else
        {
          this.map.getView().setCenter(olProj.fromLonLat([Number(this.filteredListOfMeters[0].longitude), Number(this.filteredListOfMeters[0].latitude)]));
        }
          this.map.getView().setZoom(20);
      }
      // else{
      //   // this.removeInactiveRedAndBlueMarkers();
      //   // this.setUpMarkerPositions(this.filteredListOfMeters);
      //   // this.changeLocation(this.selectedLocation);
     
      // }
      

    }else{
      console.log('init - no meter data');
    }  
  }
  

}
