import { Component, OnInit, ViewEncapsulation, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Itinerary } from '../models/Itinerary';
import { Meter } from '../models/Meter';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { StoreService } from '@app/shared/services/store.service';

export interface Location{
  lat: number;
  lng: number;
  i: number;
}

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItineraryComponent implements OnInit, DoCheck {

  //IMPORTANT!! DOCS : https://github.com/angular/components/blob/master/src/google-maps/google-map/README.md

  keyword = 'meterNumber';

  placeholder = "Search meter"

  //id to query the data for api
  public itineraryData: Itinerary

  //list of meters
  public listOfMeters: Array<Meter> = []
  public listOfMetersCopy: Array<Meter> = []
  public autosuggestList: Array<any> = []

  savedData: Location = {
    lat: 0,
    lng: 0,
    i: 0
  };

  isLocationChanged: boolean;
  updatedMeterNumber: string;
  

  constructor(
    private activatedRoute: ActivatedRoute, 
    private hhtApiService: HhtApiService,
    private storeService: StoreService
  ) { }

  public id:string
  public readings: any
  public deviceCode: any
  public readerCode: any
  public dataFilter:any
  public completionPercent: any
  public month:any
  public year:any
  public itineraryRequest:any
  public meterRequest:any
  

  startTime: string = '';
  endTime: string = '';

  email: string;
  accountType: string;
  hideLocationTab: boolean = false;

  p: number = 1;
  public searchText : string;
  activeId: string = 'Details';

  ngOnInit() {

    this.isLocationChanged = false;

    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.readings = params.readings;
      this.deviceCode = params.deviceCode;
      this.readerCode = params.readerCode;
      this.dataFilter = "ALL";
      this.month = params.month;
      this.year = params.year;
      this.completionPercent = params.completionPercent;
      this.email = params.email;
      this.accountType = params.accountType;
      console.log('month',params.month);
      console.log('Account Type', this.accountType);
      this.hideLocationTab = ['NONGP_DATA_STATION_OFFICER','DMSA'].includes(this.accountType);
    })
    this.hhtApiService.getItineraryById(this.id, this.readings, this.deviceCode, this.readerCode, this.dataFilter, this.month, this.year,this.email).subscribe(resp=>{
      this.itineraryRequest = resp['body'];
      this.startTime = resp['body']['read_start_time'];
      this.endTime = resp['body']['read_end_time'];
      console.log(this.itineraryRequest)
      this.meterRequest = resp['body']['listofMeter'];
    })
  }

  ngDoCheck(){   
    if(this.storeService.get('isLocationUpdated')){
      this.isLocationChanged = true;
      this.hhtApiService.getItineraryById(this.id, this.readings, this.deviceCode, this.readerCode, this.dataFilter, this.month, this.year,this.email).subscribe(resp=>{
        this.itineraryRequest = resp['body']
        this.startTime = resp['body']['read_start_time'];
        this.endTime = resp['body']['read_end_time'];
        console.log(this.itineraryRequest)
        this.meterRequest = resp['body']['listofMeter'];
      })
      this.storeService.set('isLocationUpdated', false);
      this.updatedMeterNumber = this.storeService.get('updatedMeterNumber');

      setTimeout(() => {
        this.isLocationChanged = false;
      }, 3000);      
    }
  }

}
