import { Injectable, Inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AesService {

  constructor(
    @Inject('key') private key: string
  ) {
    this.key = CryptoJS.SHA256(key);
  }

  public decrypt(ciphertext: string) {
    const decrypted =  CryptoJS.AES.decrypt(ciphertext, this.key, { mode: CryptoJS.mode.ECB});
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}