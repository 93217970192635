import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HhtApiService } from '@shared/services/hht-api.service'; 
import { PrintService } from '@shared/services/print.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  itineraryCode: string="";
  route: string="";

  listOfReports: Array<string> = [
    'Listing of Itineraries',
    'Addition of Itineraries',
    'Accomplishment Report',
    'Summary of Accomplished Readings',
    'Meters with Field Findings',
    'Removed and Installed Meter Seals',
    'Remarks Inputted to an Itinerary',
    'Previously and/or Currently Accepted Itineraries',
    'Daily Assignment of Itineraries',
    'Handling of Itineraries'
  ];
  monthArray: Array<any> = [
    {key: '1', value: 'January'},
    {key: '2', value: 'February'},
    {key: '3', value: 'March'},
    {key: '4', value: 'April'},
    {key: '5', value: 'May'},
    {key: '6', value: 'June'},
    {key: '7', value: 'July'},
    {key: '8', value: 'August'},
    {key: '9', value: 'September'},
    {key: '10', value: 'October'},
    {key: '11', value: 'November'},
    {key: '12', value: 'December'}
  ];
  yearArray: Array<string> = [];
  routesArray: Array<any> = [];
  filterRoutesArray: Array<any> = [];
  itineraryCodeArray: Array<any> = [];
  filterItineraryCodeArray: Array<any> = [];

  currentSelectedReport: string = '';
  highlightedRowIndex: number = null;
  contentLoading: boolean = false;

  validDateParameters: boolean = false;
  
  fileString: string = '';

  subscription$: Array<Subscription> = [];

  reportsFormGroup: UntypedFormGroup = new UntypedFormGroup({
    route: new UntypedFormControl(''),
    cycle: new UntypedFormControl(''),
    itineraryCode: new UntypedFormControl(''),
    readingCenterCode: new UntypedFormControl(''),
    month: new UntypedFormControl(''),
    year: new UntypedFormControl(''),
    readDate: new UntypedFormControl(''),
    date_from: new UntypedFormControl(''),
    date_to: new UntypedFormControl('')
  });

  currentUserEmail: string = '';
  currentReadingCenterCode: string = '';
  searchRoute: string = '';
  searchItineraryCode: string = '';
  itineraryParam: any = { 
    status: '', 
    itineraryCode: '', 
    email: '', 
    route: '' 
  };

  constructor(private hhtApiService: HhtApiService,
              private printService: PrintService,
              private toastrService: ToastrService,
              public datePipe: DatePipe              
              ) { }

  ngOnInit(): void {
    let currentYear = new Date().getFullYear();
    let startYear = currentYear - 5;
    for(let year = startYear; year <= currentYear; year++){
      this.yearArray.push(String(year).toString());
    }
    Auth.currentUserInfo().then(user =>{
      this.currentUserEmail = String(user['username']).replace(environment.adPrefix, '');
      this.subscription$.push(
        this.hhtApiService.getReaderCodesbyEmail(this.currentUserEmail).subscribe(data =>{
        this.currentReadingCenterCode = !!data['body']['results'][0]['defaultReadingCenterCode'] ? 
                                        data['body']['results'][0]['defaultReadingCenterCode'] :
                                        data['body']['results'][0]['readingCenterCode'];
      }));
    });
    this.subscription$.push(
      this.reportsFormGroup.get('route').valueChanges.subscribe(value=>{
        this.reportsFormGroup.get('itineraryCode').setValue('');
        this.itineraryParam.route = value;
        if(!!this.reportsFormGroup.get('route').value){
          this.hhtApiService.getItinerariesByStatusOrCode(this.itineraryParam)
                          .pipe(take(1))
                          .subscribe(result =>{
                            this.itineraryCodeArray = result['body']['itineraries'];
                            this.searchFilter('itn');
                          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription$.forEach((sub: Subscription) => {
      sub.unsubscribe();
    })
  }

  selectReport(report){
    this.itineraryCodeArray = [];
    this.routesArray = [];
    this.filterRoutesArray = [];
    this.filterItineraryCodeArray = [];
    this.searchItineraryCode = '';
    this.searchRoute = '';
    this.validDateParameters = false;
    this.reportsFormGroup.reset();
    this.removeFormGroupValidators();
    this.itineraryParam.status = '';
    this.itineraryParam.route = '';
    switch(report){
      case this.listOfReports[0]:{ //listing of itineraries
        this.itineraryParam.status = 'RECEIVED';
        this.itineraryParam.email = this.currentUserEmail;
        this.contentLoading = true;
        this.subscription$.push(
              this.hhtApiService.getRoutes('', this.currentUserEmail, 'RECEIVED')
              .subscribe((results: any)=>{
                  this.routesArray = results['body']['results'];
                  this.searchFilter('route');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('route').addValidators([Validators.required]);
        this.reportsFormGroup.get('itineraryCode').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[1]: { //Addition of Itineraries
        this.reportsFormGroup.get('month').addValidators([Validators.required]);
        this.reportsFormGroup.get('year').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[2]: { //Accomplishment Report
        this.itineraryParam.status = 'HANDLED';
        this.itineraryParam.email = this.currentUserEmail;
        this.contentLoading = true;
        this.subscription$.push(
          this.hhtApiService.getRoutes('', this.currentUserEmail, 'HANDLED')
              .subscribe((results: any)=>{
                  this.routesArray = results['body']['results'];
                  this.searchFilter('route');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('route').addValidators([Validators.required]);
        this.reportsFormGroup.get('itineraryCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('readDate').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[3]: { //Summary of Accomplished Readings
        //get current user's reading center code
        this.reportsFormGroup.get('readingCenterCode').setValue(this.currentReadingCenterCode);
        this.reportsFormGroup.get('readingCenterCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('readDate').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[4]: { //Meter Reading Field Finding Report
        this.itineraryParam.status = '';
        this.itineraryParam.email = this.currentUserEmail;
        this.contentLoading = true;
        this.subscription$.push(
          this.hhtApiService.getRoutes('', this.currentUserEmail, '')
              .subscribe((results: any)=>{
                  this.routesArray = results['body']['results'];
                  this.searchFilter('route');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('route').addValidators([Validators.required]);
        this.reportsFormGroup.get('itineraryCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_from').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_to').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[5]: { //Removed and Installed Meter Seals
        this.itineraryParam.status = '';
        this.itineraryParam.email = this.currentUserEmail;
        this.contentLoading = true;
        this.subscription$.push(
            this.hhtApiService.getRoutes('', this.currentUserEmail, '')
              .subscribe((results: any)=>{
                  this.routesArray = results['body']['results'];
                  this.searchFilter('route');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('route').addValidators([Validators.required]);
        this.reportsFormGroup.get('itineraryCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_from').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_to').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[6]: { //Remarks Inputted to an Itinerary
        this.itineraryParam.status = '';
        this.itineraryParam.email = this.currentUserEmail;
        this.itineraryParam.route = '';
        this.contentLoading = true;
        this.subscription$.push(
          this.hhtApiService.getItinerariesByStatusOrCode(this.itineraryParam)
              .subscribe((results: any)=>{
                  this.itineraryCodeArray = results['body']['itineraries'];
                  this.searchFilter('itn');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('itineraryCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_from').addValidators([Validators.required]);
        this.reportsFormGroup.get('date_to').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[7]: { //Previously and/or Currently Accepted Itineraries
        //get current user's reading center code
        this.reportsFormGroup.get('readingCenterCode').setValue(this.currentReadingCenterCode);
        this.reportsFormGroup.get('readingCenterCode').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[8]: { //Daily Assignment of Itineraries
        this.contentLoading = true;
        this.subscription$.push( //also get the current user's reading center code
          this.hhtApiService.getRoutes('', this.currentUserEmail, '')
              .subscribe((results: any)=>{
                  this.routesArray = results['body']['results'];
                  this.searchFilter('route');
                  this.contentLoading = false;
              })
        );
        this.reportsFormGroup.get('readingCenterCode').setValue(this.currentReadingCenterCode);
        this.reportsFormGroup.get('route').addValidators([Validators.required]);
        this.reportsFormGroup.get('readingCenterCode').addValidators([Validators.required]);
        this.reportsFormGroup.get('cycle').addValidators([Validators.required]);
        break;
      }
      case this.listOfReports[9]: { //Handling of Itineraries
        //get the current user's reading center code
        this.reportsFormGroup.get('readingCenterCode').setValue(this.currentReadingCenterCode);
        this.reportsFormGroup.get('readingCenterCode').addValidators([Validators.required]);
        break;
      }
    }
    this.reportsFormGroup.updateValueAndValidity();
  }

  generateReport(report){
    switch(report){
      case this.listOfReports[0]:{ //listing of itineraries
        let params = {
          route: this.reportsFormGroup.get('route').value,
          itineraryCode: this.reportsFormGroup.get('itineraryCode').value
        }
        this.printService.printListingOfItineraries(params);
        break;
      }
      case this.listOfReports[1]: { //Addition of Itineraries
        console.log(this.currentReadingCenterCode)
        let params = {
          readingCenterCode: this.currentReadingCenterCode, //this.reportsFormGroup.get('readingCenterCode').value,
          month: this.reportsFormGroup.get('month').value,
          year: this.reportsFormGroup.get('year').value
        }
        this.printService.printAdditionOfItineraries(params);
        break;
      }
      case this.listOfReports[2]: { //Accomplishment Report
        let params = {
          route: this.reportsFormGroup.get('route').value,
          itineraryCode: this.reportsFormGroup.get('itineraryCode').value,
          date: this.reportsFormGroup.get('readDate').value
        }
        this.printService.printAccomplishmentReports(params);
        break;
      }
      case this.listOfReports[3]: { //Summary of Accomplished Readings
        let params = {
          readingCenterCode: this.reportsFormGroup.get('readingCenterCode').value,
          date: this.reportsFormGroup.get('readDate').value
        }
        this.printService.printSummaryAccomplishmentReports(params);
        break;
      }
      case this.listOfReports[4]: { //Meter Reading Field Finding Report
        let params = {
          route: this.reportsFormGroup.get('route').value,
          itineraryCode: this.reportsFormGroup.get('itineraryCode').value,
          date_from: this.reportsFormGroup.get('date_from').value,
          date_to: this.reportsFormGroup.get('date_to').value
        }
        this.printService.printMetersWithFieldFindings(params);
        break;
      }
      case this.listOfReports[5]: { //Removed and Installed Meter Seals
        let params = {
          route: this.reportsFormGroup.get('route').value,
          itineraryCode: this.reportsFormGroup.get('itineraryCode').value,
          date_from: this.reportsFormGroup.get('date_from').value,
          date_to: this.reportsFormGroup.get('date_to').value
        }
        this.printService.printRemovedAndInstalledMeterSeals(params);
        break;
      }
      case this.listOfReports[6]: { //Remarks Inputted to an Itinerary
        let params = {
          itineraryCode: this.reportsFormGroup.get('itineraryCode').value,
          date_from: this.reportsFormGroup.get('date_from').value,
          date_to: this.reportsFormGroup.get('date_to').value
        }
        this.printService.printItineraryRemarksReport(params);
        break;
      }
      case this.listOfReports[7]: { //Previously and/or Currently Accepted Itineraries
        let params = {
          readingCenterCode: this.reportsFormGroup.get('readingCenterCode').value,
        }
        this.printService.printPreviousAndOrCurrentAcceptedItineraries(params);
        break;
      }
      case this.listOfReports[8]: { //Daily Assignment of Itineraries
        let params = {
          route: this.reportsFormGroup.get('route').value,
          readingCenterCode: this.reportsFormGroup.get('readingCenterCode').value,
          cycle: this.reportsFormGroup.get('cycle').value
        }
        this.printService.printDailyAssignmentOfItineraries(params);
        break;
      }
      case this.listOfReports[9]: { //Handling of Itineraries
        let params = {
          readingCenterCode: this.reportsFormGroup.get('readingCenterCode').value,
        }
        this.printService.printHandlingOfItineraries(params);
        break;
      }
    }
  }

  removeFormGroupValidators(){
    this.reportsFormGroup.get('route').removeValidators([Validators.required]);
    this.reportsFormGroup.get('cycle').removeValidators([Validators.required]);
    this.reportsFormGroup.get('itineraryCode').removeValidators([Validators.required]);
    this.reportsFormGroup.get('readingCenterCode').removeValidators([Validators.required]);
    this.reportsFormGroup.get('month').removeValidators([Validators.required]);
    this.reportsFormGroup.get('year').removeValidators([Validators.required]);
    this.reportsFormGroup.get('readDate').removeValidators([Validators.required]);
    this.reportsFormGroup.get('date_from').removeValidators([Validators.required]);
    this.reportsFormGroup.get('date_to').removeValidators([Validators.required]);
    this.reportsFormGroup.updateValueAndValidity();
  }

  searchFilter(dropdownName: string){
    if(dropdownName == 'itn'){ //itineraryCode
      this.filterItineraryCodeArray = this.itineraryCodeArray.filter(itn=>{return String(itn.itineraryCode).includes(this.searchItineraryCode)});
    }else if(dropdownName == 'route'){//route
      this.filterRoutesArray = this.routesArray.filter(route=>{return String(route.route).includes(this.searchRoute)});
    }else if(dropdownName == 'itnMulti'){ //multiple itinerary
      this.filterItineraryCodeArray = this.itineraryCodeArray.filter(itn=>{
        return String(itn.itineraryCode).includes(this.searchItineraryCode) ||
              this.reportsFormGroup.get('itineraryCode').value?.includes(itn.itineraryCode);
      });
    }
  }

  convertMstsToTextFile(data, filename, dataresults) {
    this.fileString = ''
    // const results = data['results'];
    const results = dataresults;
    const date = data['date'];
    const time = data['time'];
   
    const space = (count) => { return ' '.repeat(count); };
    const modifiedData = results.map((result) => {
      let textHeader = `                       MERALCO-CUSTOMER MANAGEMENT SYSTEM\n`;
      textHeader += `                                  METER SEAL REPORT              DATE :${this.datePipe.transform(date, 'MM/d/yyyy')}\n`;
      textHeader += `                                                                 TIME :${time}\n`;
      textHeader += `USER : ${this.currentUserEmail}${space(26 - this.currentUserEmail.length > 0 ? 26 - this.currentUserEmail.length : 1)}\n\n\n`;
      textHeader += `             READING CENTER  : ${result['readingCenterCode']}        THEORETICAL RDG DATE: ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yyyy') || ''}\n`;
      textHeader += `             ROUTE           : ${result['route']}          ACTUAL RDG DATE     : ${this.datePipe.transform(result['readDate'], 'M/d/yyyy') || ''}\n`;
      textHeader += `             CYCLE           : ${result['cycle']}          READER CODE         : ${result['readerCode']}\n`;
      textHeader += `             ITINERARY NUMBER: ${result['itineraryCode'].substr(result['itineraryCode'].length - 4)}        HHT CODE            : ${result['device_code']}\n\n`;
      textHeader += `-------------------------------------------------------------------------------\n`;
      textHeader += `        ITEM NO.  INSTALLED SEAL  REMOVED SEAL  METER NUMBER  LOT ID NO.\n`;
      textHeader += `-------------------------------------------------------------------------------\n`;
      let table = [];
      result['lots'].forEach(lot => {
        lot['meteringPoints'].forEach(meteringPoint => {
          meteringPoint['meters'].forEach(meter => {
            if (meter['meterSeal'].length > 0) {
              meter['meterSeal'].forEach(seal => {
                const getItemNoMiddle = (8 - String(seal['item_no']).length);
                const getInstalledNoMiddle = (15 - String(seal['installed']).length);
                const getRemovedMiddle = (13 - String(seal['removed']).length);
                const getMeterNoMiddle = (16 - String(meter['meterNo']).length);
                const getLotIdNoMiddle = (11 - String(lot['lot_id_number']).length);
                
                table.splice(seal['item_no'], 0, `${space(11)}${seal['item_no']}${space(Math.floor(getItemNoMiddle))}${seal['installed']}${space(getInstalledNoMiddle)}${seal['removed']}${space(Math.floor(getRemovedMiddle))}${meter['meterNo']}${space(getMeterNoMiddle)}${lot['lot_id_number']}\n`);
              });
            }
          });
        });
      });


      filename = `${this.datePipe.transform(date, 'yyyyMMdd')}` + `_` + `${result['readingCenterCode']}` + `_MR`
      return textHeader + table.join('') + `\n` ;
    });
    const textFile = new File([modifiedData.join('')], filename, { type: "text/plain" });
    
    this.fileString = modifiedData.join('')
    
    return textFile;
  }

  convertToJsonFile(data, filename) {
    const jsonFile = new File([JSON.stringify(data)], filename, { type: "application/json" });
    return jsonFile;
  }

  sendMSTS(){
    let params = {email: this.currentUserEmail};
    this.hhtApiService.exportMSTSreport(params).subscribe(result => {
      let data = result['body'];
      console.log('results');
      console.log(data['results']);
      if (data['results']=="no available data"){
        this.toastrService.success('No meter seals were recorded today.', 
        'Sending to MSTS Failed', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
      }
      else{
          // NOTE: This is the txt file that will use in the endpoint provided for MSTS
          // //file per itinerary
          // data['results'].forEach(itinerary=>{
          //   var itineraryArray = [];
          //   itineraryArray.push(itinerary)
          //   const mstsTextFile = this.convertMstsToTextFile(data, 'msts.txt', itineraryArray);          
          //   console.log(mstsTextFile);
  
          //   const blob = new Blob([mstsTextFile], { type: 'text/plain' });
          //   const url= window.URL.createObjectURL(blob);
          //   window.open(url);

          //   let requestBody = {
          //     blobFile: this.fileString,
          //     fileName: mstsTextFile.name + '.txt'
          //   }

          //   this.hhtApiService.exportMSTSreportBlob(requestBody).subscribe((res: any[])=>{
          //   });

          // });
          

          //file per RCC
          const arrayWithDuplicates = data['results'].map(item => item.readingCenterCode);
          const distinctArray = arrayWithDuplicates.filter((n, i) => arrayWithDuplicates.indexOf(n) === i);

          distinctArray.forEach(rcc=>{
            var itineraryArray = [];
            itineraryArray = data['results'].filter(item => item.readingCenterCode.includes(rcc))
            console.log('test')
            console.log(rcc)
            console.log(itineraryArray)
            const mstsTextFile = this.convertMstsToTextFile(data, 'msts.txt', itineraryArray);          
            console.log(mstsTextFile);

            const blob = new Blob([mstsTextFile], { type: 'text/plain' });
            const url= window.URL.createObjectURL(blob);
            window.open(url);
            
            // use API generation of files instead
            // let requestBody = {
            // blobFile: this.fileString,
            // fileName: mstsTextFile.name + '.txt'
            // }

            // this.hhtApiService.exportMSTSreportBlob(requestBody).subscribe((res: any[])=>{
            // });

          });

          // TODO: change the API with the finalized endpoint
          const mstsJsonFile = this.convertToJsonFile(data, "msts.json");
          // this.hhtApiService.validateUploadToCISFile(mstsJsonFile).subscribe((res: any[])=>{

          // });
        this.toastrService.success('List of meter seals successfully sent to MSTS.', 
        'Sent to MSTS', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
      }
    })
  }

  checkDateParameters(){
    if(this.reportsFormGroup.get('date_from').value > this.reportsFormGroup.get('date_to').value){
      this.validDateParameters = false;
    }
    else{
      this.validDateParameters = true;
    }
    console.log("checkDateParameters: " + this.validDateParameters);
  }

}
