import { Component, DoCheck, OnInit, OnDestroy} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { Hub, Auth } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InformationComponent } from '@shared/modals/information/information.component';

import Amplify from '@aws-amplify/core'
import Auth_ from '@aws-amplify/auth'
import { request } from 'http';
import { environment } from '@environments/environment';

Amplify.configure({
  Auth_: {
    //identityPoolId: 'ap-southeast-1_OxfWFxzuX',
    region : 'ap-southeast-1',
    // dev -'6gbvhjbkn58j5scluj8p8999jk'
    // uat -'6u1nno2r4c049uq3mmalimnv7f'
    // prod - '7rgikb252bpacfa0nrplrjcrve'
    userPoolId: '6u1nno2r4c049uq3mmalimnv7f',
    
    userPoolWebClientId: 'hhtwebf47570b1_app_clientWeb',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});
@Component({
  selector: 'app-split-itinerary',
  templateUrl: './split-itinerary.component.html',
  styleUrls: ['./split-itinerary.component.scss']
})
export class SplitItineraryComponent implements OnInit, DoCheck, OnDestroy {
  isLoading: boolean = true;

  // sort
  page=1;
  prevPage=1;
  defPage=1;
  public toggleSort: boolean = true;
  public isSortedAgain: string;
  public selectedSort: string;

  public defaultMonth: string = "";
  public defaultYear: string = "";

  p: number = 1;
  public searchText : string;

  splitItineraryMeterArray = [];
  itineraryNumber: string;

  userAccountInfo = [];

  searchString = '';
  limit = 10;
  offset= 0;
  totalCount: number;

  monthParse = {
    "01":"January",
    "02":"February",
    "03":"March",
    "04":"April",
    "05":"May",
    "06":"June",
    "07":"July",
    "08":"August",
    "09":"September",
    "10":"October",
    "11":"November",
    "12":"December"
  }

  monthOptions = [
    {
      month: 'January',
      value: '01'
    },
    {
      month: 'February',
      value: '02'
    },
    {
      month: 'March',
      value: '03'
    },
    {
      month: 'April',
      value: '04'
    },
    {
      month: 'May',
      value: '05'
    },
    {
      month: 'June',
      value: '06'
    },
    {
      month: 'July',
      value: '07'
    },
    {
      month: 'August',
      value: '08'
    },
    {
      month: 'September',
      value: '09'
    },
    {
      month: 'October',
      value: '10'
    },
    {
      month: 'November',
      value: '11'
    },
    {
      month: 'December',
      value: '12'
    }
  ]
  convertMonth:number;
  selectedMonth:string;
  yearOptions = [];
  selectedYear:string;
  userEmail:string;
  userName:string;

  cognitoUserName:string;
  Password0: string = '';

  assignToRoverFlag: boolean = false;
  uploadToCISFlag: boolean = false;
  uploadToCISIsLoading: boolean = false;
  assignToRoverList: Array<any> = [];
  deviceCodeList: Array<any> = [];
  deviceCode$: Subscription;
  assignToRover$: Subscription;
  assignToRoverModal: NgbModalRef;

  sortKey: string = '';
  sortOrder: string = '';

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private hhtApiService: HhtApiService,
    private authService: AuthService,
    private modal: NgbModal
    ) { }
    

  ngOnInit() {

    console.log('-----')
    Auth.currentAuthenticatedUser().then(data => {
      this.userEmail = String(data['username']).replace(environment.adPrefix, '');
      this.authService.assignCurrentUser(this.userEmail);

      this.route.queryParams
        .subscribe(params => {
          this.itineraryNumber = params.itineraryNumber;
        }
      );

      this.deviceCode$ = this.hhtApiService.getDeviceCodes('AVAILABLE')
        .pipe(take(1))
        .subscribe(result =>{
          this.deviceCodeList = result['body']['results'];
          console.log(this.deviceCodeList)
          
        },
        error =>{
          alert('Error fetching Device Codes: \n'+ error)
        })

      this.hhtApiService.getSplitItineraryMeters(this.itineraryNumber, this.limit, this.offset).subscribe((res: any[])=>{    
        this.splitItineraryMeterArray = res['body']['results'];
        this.totalCount = res['body']['total_count'];
        console.log('totalCount',this.totalCount)
        console.log('itineraryArray',this.splitItineraryMeterArray)
        this.isLoading = false;
      }) 
    
      
      
      console.log(Auth.currentUserInfo());
      Auth.currentSession().then( data => {
        
        //console.log('TOKEN-->',data.getIdToken().getJwtToken())
        this.userName = String(data.getIdToken().payload['cognito:username']).replace(environment.adPrefix, '');
        //this.myService.changeMessage(this.userName)
        console.log("Email :" + this.userEmail)
        console.log("UserName :" + this.userName)
        
        this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
          console.log(res['body']['UserInfo'])
          this.userAccountInfo = res['body']['UserInfo']
          let currentUser = this.authService.getCurrentUser();
          
          if(this.userAccountInfo.length > 0){       
            console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
            const d = new Date();
            let year = d.getFullYear();
            let month = d.getMonth() + 1;
            let day = d.getDate();
            console.log(year + '-' + month + '-' + day)

            if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

            }
            else {
              if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
                this.router.navigate(['hht/change-password', {mustChangePass: true}], 
                { 
                });
              }
            }
            
          }
          else {
            console.log('Credential does not exist in db')
            if(!currentUser.isAD){ //temporary workaround so that AD user won't redirect to change password. Need more discussion on this.
              this.router.navigate(['hht/change-password', {mustChangePass: true}], 
              { 
              });
            }
            
          }
        })
      });
    });

    

    
  }

  ngDoCheck(){    
    if(typeof this.searchText !== "undefined" && this.searchString !== this.searchText){
      this.searchText = this.searchText.trim();
      this.searchString = this.searchText;
      this.splitItineraryMeterArray = [];
      this.totalCount = 0;
      this.hhtApiService.getSplitItineraryMeters(this.itineraryNumber, this.limit, this.offset).subscribe((res: any[])=>{      
        if(typeof res['body']['itineraries'] === "object"){
          this.splitItineraryMeterArray = res['body']['results'];
          this.totalCount = res['body']['total_count'];
        }        
      })
    }
  }

  ngOnDestroy(): void {
    if(!!this.deviceCode$){
      this.deviceCode$.unsubscribe();
    }
    if(!!this.assignToRover$){
      this.assignToRover$.unsubscribe();
    }
  }

  onIteneraryRowClick(item) {
    console.log(item)
    this.router.navigate(['hht/itinerary-list/selected-itinerary'], 
    { 
      //relativeTo: this.route,
      queryParams: {
      'id': item.itineraryNumber,
      'readings': item.readings,
      'deviceCode':item.device_code,
      'readerCode':item.center_code,
      'month':item.month,
      'year':item.YEAR
      }
    });
  }

  onSort(param: string) {
    let currentIdx = (this.defPage - 1) * this.limit
    let currentList = this.splitItineraryMeterArray.slice(currentIdx, currentIdx + this.limit)

    if (this.prevPage !== this.page){
      this.isSortedAgain = ''
      this.prevPage = this.page
      this.toggleSort = true;
    }
    
    if (param === this.isSortedAgain) {
      this.isSortedAgain = param

      if (this.selectedSort === 'asc'){
        this.selectedSort = 'desc'
        this.toggleSort = !this.toggleSort
      } else if (this.selectedSort === 'desc'){
        this.selectedSort = ''
        this.toggleSort = !this.toggleSort
      } else {
        this.selectedSort = 'asc'
      }
    } else {
      this.isSortedAgain = param
      this.selectedSort = 'asc'
      this.toggleSort = true
    }

    if (this.selectedSort === 'asc' || this.selectedSort === 'desc'){
      if (this.toggleSort) {
        currentList.sort((a: any, b: any) => {          
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return -1; }
          if (textA > textB) { return 1; }
          if (textA === null) { return 1; }
          if (textB === null) { return -1; }
          return 0;
        })
      } else {
        currentList.sort((a: any, b: any) => { 
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return 1; }
          if (textA > textB) { return -1; }
          return 0;
        })
      }    
      Array.prototype.splice.apply(this.splitItineraryMeterArray, [currentIdx, currentList.length].concat(currentList));
    }
  }

  onPageChange(event){
    this.prevPage = this.page;
    this.page = event;
    this.selectedSort = '';
    const offset = (event - 1) * this.limit;
    this.isLoading = true;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, offset, this.selectedYear, this.selectedMonth, this.userEmail, '', this.sortKey, this.sortOrder).subscribe((res: any[])=>{      
      this.splitItineraryMeterArray= res['body']['results'];
      console.log(this.splitItineraryMeterArray)
      this.isLoading = false;
    }) 
  }
  
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  assignToRoverFlagFn(){
    let arrayTest = this.splitItineraryMeterArray.filter(data => { return !!data.checked });
    let test = new Set(arrayTest.map(selected => String(selected.status).toUpperCase()));
    this.assignToRoverFlag = !!test.has('RECEIVED') && test.size == 1; //selected itineraries must only be with status RECEIVED to enable assign to rover
    this.uploadToCISFlag = !!test.has('HANDLED') && test.size == 1; //selected itineraries must only be with status HANDLED to enable upload to cis
  }

  assignToRoverListFn(modalContent){
    this.deviceCodeList = [];
    this.assignToRoverList = this.splitItineraryMeterArray.filter(itineraries =>{
      return !!itineraries.checked && String(itineraries.status).toUpperCase() == 'RECEIVED';
    });
    this.deviceCode$ = this.hhtApiService.getDeviceCodes('AVAILABLE')
        .pipe(take(1))
        .subscribe(result =>{
          this.deviceCodeList = result['body']['results'];
          console.log(this.deviceCodeList)
          
        },
        error =>{
          alert('Error fetching Device Codes: \n'+ error)
        })
    this.assignToRoverModal = this.modal.open(modalContent);
  }

  assignToRover(){
    //validations
    //check if there are duplicate device codes being used
    const uniqueValues = new Set(this.assignToRoverList.map(roverList => roverList.deviceCode));
    if(uniqueValues.size < this.assignToRoverList.length){
      const modalRef = this.modal.open(InformationComponent);
      modalRef.componentInstance.header = 'Information';
      modalRef.componentInstance.information = 'Itineraries must have its own unique device code.'
      modalRef.componentInstance.positiveButton = 'Close';
      return;
    }
    
    //assigning proper
    let itnAssigned: Array<any> = [];
    console.log(this.assignToRoverList);
    for(let itineraries of this.assignToRoverList){
      itnAssigned.push({
        itineraryCode: itineraries.itineraryNumber,
        deviceCode: itineraries.deviceCode
      })
    }
    const requestPayload = { data: itnAssigned };
    console.log(requestPayload);
    this.isLoading = true;
    this.hhtApiService.assignToRover(requestPayload)
                      .pipe(take(1))
                      .subscribe(result =>{
                        const modalRef = this.modal.open(InformationComponent);
                        modalRef.componentInstance.header = 'Information';
                        modalRef.componentInstance.information = result['body']['results'];
                        modalRef.componentInstance.positiveButton = 'Close';
                        this.assignToRoverModal.dismiss();
                        this.assignToRoverFlag = false;
                        //fetch al itineraries again, but consider making one seperate function for getting itineraries as its always being used in this component (best practice!)
                        this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.userEmail, '', this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
                          this.splitItineraryMeterArray = res['body']['itineraries'];
                          this.totalCount = res['body']['details'][0]['total_count'];
                          console.log('totalCount',this.totalCount)
                          console.log('itineraryArray',this.splitItineraryMeterArray)
                          this.isLoading = false;
                        }) 
                      },
                      error =>{
                        alert('Error assigning to rover: \n'+ error);
                      })
  }

  uploadtoCISFn(){
    let loadingModal = this.modal.open(InformationComponent, {backdrop: 'static'});
    loadingModal.componentInstance.header = 'Uploading to CIS';
    loadingModal.componentInstance.loader = true;
    loadingModal.componentInstance.hasFooterAction = false;
    this.uploadToCISIsLoading = true;
    let requestBody = {
      data: [],
      email: this.authService.getCurrentUser().email
    }
    for(let itinerary of this.splitItineraryMeterArray){
      if(!!itinerary.checked){
        requestBody.data.push({
          itineraryCode: itinerary.itineraryNumber
        })
      }
    }
    console.log('Request Body', requestBody);
    //call generateAlo047 first
    this.hhtApiService.uploadToCIS(requestBody)
                      .pipe(take(1))
                      .subscribe(uploadResult =>{
                        const modalRef = this.modal.open(InformationComponent);
                        modalRef.componentInstance.header = 'Information';
                        modalRef.componentInstance.information = uploadResult['body']['results'] //backend API response will be used as the success message
                        modalRef.componentInstance.positiveButton = 'Close';
                        this.uploadToCISIsLoading = false;
                        loadingModal.dismiss();
                        this.isLoading = true;
                        //fetch al itineraries again, but consider making one seperate function for getting itineraries as its always being used in this component (best practice!)
                        this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth, this.userEmail, '', this.sortKey, this.sortOrder).subscribe((res: any[])=>{    
                            this.splitItineraryMeterArray = res['body']['itineraries'];
                            this.totalCount = res['body']['details'][0]['total_count'];
                            console.log('totalCount',this.totalCount)
                            console.log('itineraryArray',this.splitItineraryMeterArray)
                            this.isLoading = false;
                        });
                      },
                      error =>{
                        const modalRef = this.modal.open(InformationComponent);
                        modalRef.componentInstance.header = 'Error';
                        modalRef.componentInstance.information = error['message'] //backend API response will be used as the error message
                        modalRef.componentInstance.positiveButton = 'Close';
                        loadingModal.dismiss();
                        this.uploadToCISIsLoading = false;
                      });
  }
  
}
